import { Component, OnInit } from '@angular/core';
import { interval, timer } from 'rxjs';
import { delayWhen, tap } from 'rxjs/operators';
import {
  fadeInOnEnterAnimation,
  fadeOutOnLeaveAnimation,
  fadeInAnimation,
  fadeOutAnimation,
  bounceInAnimation,
  pulseAnimation,
} from 'angular-animations';

@Component({
  selector: 'app-temp',
  templateUrl: './temp.component.html',
  styleUrls: ['./temp.component.css'],
  animations: [
    fadeInOnEnterAnimation(),
    fadeInAnimation(),
    fadeOutAnimation(),
    fadeOutOnLeaveAnimation(),
    bounceInAnimation(),
    pulseAnimation()
  ]
})
export class TempComponent implements OnInit {
  title = 'eric';
  texts: string[] = ['Hello,', 'my', 'name', 'is', 'Eric', 'and', 'soon', 'the', 'content', 'here', 'will' , 'be', 'a', 'tad', 'more', 'meaningful', 'than', 'this', 'boring', 'text.', 'Stay', 'tuned', 'and', 'have','a', 'great', 'day.', ':)'];
  text: string = null;
  constructor() { }

  ngOnInit(): void {
    const time = timer(0, 300);
    let count = 0;
    time.subscribe(x => {
      document.getElementById("text").innerText = this.texts[count++];
      if(count === this.texts.length) {
        count = 0;
      }
    })
  }
}
